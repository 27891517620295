@font-face {
  font-family: 'anurati'; 
  src: url('../components/Fonts/Anurati-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'shree';
  src: url('../components/Fonts/Shree-Devanagari-714.ttf') format('truetype');
}

html,
body {
padding: 0;
margin: 0;
width: 100%;
height: 100%;
font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
text-rendering: optimizeLegibility;
color: #888;
background: rgba(255, 255, 255, 0.825);
background-color:#f9faf7;
overflow: hidden;
}

.homePageHeader {
  width:100vw;
  height:6em;
  position: relative;
  top:0em;
}

.center-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width:100vw;
  position: relative;
  bottom:0;
}


.logo-text{
  color:white;
  font-size: 1.5em;
  font-family: 'shree';
  position:relative;
  margin-left: .2em;
  font-weight: bold;
}

.chat-container{
 
  background: #f9faf7;
  position: absolute;
  left: 13em;
  right:13em;
  top:4em;
  bottom:1em;
  padding-top:.2em;
  font-family: 'shree', sans-serif;
  display: flex;
  justify-content: center;
}


.chat-input{
  position:absolute;
  bottom:2em;
  display: flex;
}

.text-input{
  width:45em;
  padding:1em;
  padding-right:4em;
  border-radius: 10px;
  border:1px solid gray;
  resize: 'none';
  overflow-y: auto; 
  overflow-x: hidden;
  min-height: 2.8em;
  max-height: 8.4em;
  word-wrap: break-word;
  white-space: pre-wrap;
  background-color: #f9faf7;
}

.submit-btn{
  position:absolute;
  bottom:.5em;
  right:2em;
  height:2.7em;
  width:2.7em;
  border-radius: 10px;
  border:0px;
  background: rgb(87,160,247);
  background: linear-gradient(90deg, rgba(87,160,247,1) 0%, rgba(36,44,245,1) 100%);
  color:white;
  transition: transform 0.3s 'cubic-bezier';
}

.submit-btn:hover{
  transform: scale(1.1);
  cursor:pointer;

}

.bubble-container{
  position:absolute;
  display:flex;
  flex-direction: column;
  top:2em;
  bottom:3em;
  right:1em;
  left:1em;
  overflow-y: scroll;
  scroll-behavior: 'smooth';
  margin-bottom:2.4em;
}

.bubble-container::-webkit-scrollbar {
  display: none;
}

@keyframes appearAnimation {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}


.user-bubble{
  opacity: 0;
  animation: appearAnimation 0.3s forwards;
  margin:1em;
  background: rgb(92,36,245);
  background: linear-gradient(90deg, rgba(92,36,245,1) 0%, rgba(87,160,247,1) 100%);
  color:white;
  width:40em;
  padding:8px;
  padding-left: 10px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-left:8px;
  padding-right:8px;
  min-height:1em;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.user-response{
  position:relative;
  left:3em;
}

.user-background span {
  display: inline; 
}


.response-bubble{
  opacity: 0;
  animation: appearAnimation 0.3s forwards;
  position:relative;
  left:.5em;
  background: #f9faf7;
  border: 1px solid rgba(0, 0, 0, 0.3);
  color:black;
  width:40em;
  padding:5px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-left:8px;
  padding-right:8px;
  min-height:1em;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.response-bubble span{
  display: inline; 
}

.dynamic-br{
  background: rgb(87,160,247);
  background: linear-gradient(90deg, rgba(87,160,247,1) 0%, rgba(36,44,245,1) 100%);
  width:100%;
  height: 0.05em;
}

.andy-bubble{
  margin-top: .75rem;
  height: 1em;
  left:3em;
  margin-left:1em;
  padding: 0.5em;
  color: black;
  font-weight: bold;

}

.user-label{
  position:relative;
  font-size: 1em;
  margin-left:2em;
  right:1em;
}

.andy-label{
  font-size: 1em;
  padding-left:.5em;
}

.chat-breaker{
  width:100%;
  height:.15em;
  background:#A93F55;
  margin-top:.35em;
  display: flex;
  justify-content: center;

}

.andy-response{
  display: flex;

}


.andy-propic{
  position:relative;
  top:.7em;
  height:2.7em;
  width:2.7em;
  margin-left:1.8em;
  filter: grayscale(100%);
}


.profile-circle{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  text-align: center;
  color:white;
  position:relative;

  background-color: #57a0f7;
  border-radius: 50%;
}

.typing{
  font-size: .5em;
}

.card{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position:relative;
  width:15em;
  height:18em;
  z-index: 1;
  background-color: #57a0f7;
  margin:2em;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);

}

.card-container{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:4em;
}

.card-header{
  text-align: center;
  font-weight:bold;
}

.chat-bubble{
  margin-top:2em;
  height:2em;
  width:2em;
}

.card-entry{
  background: linear-gradient(90deg, rgba(87,160,247,.5) 0%, rgba(36,44,245,.5) 100%);
  color:black;
  border-radius: 5px;
  padding:3px;
}

.armada-logo{
  position:absolute;
  top:1em;
  width:3em;
  height:3em;
  margin:1em;
  border-radius: 15px;
  z-index: 1;
}

.armada-text{
  position: absolute;
  width:10em;
  height:3em;
  left:1em;
  top:.3em;
  
}

.logo-container{
  display: flex;
  position: relative;
  top:2em;
  justify-content: center;
 
}